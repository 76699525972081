<template>
  <div class="user-form">
    <b-overlay :show="busy" rounded="sm">
    <portlet :title="portletTitle">
      <template v-slot:body>
        <b-form ref="user" @submit.stop.prevent="onSubmit" @reset="onReset">

          <b-form-row >

            <b-col sm="6" md="3">
              <b-form-group id="input-group-6" :state="validateState('vip_expires_in')" aria-describedby="vip_expires_in-live-feedback" label="ئالىي ئەزالىق ئاخىرلىشىش ۋاقتى" label-for="vip_expires_in">
                <datetime placeholder="بۇ يەرنى بېسىپ تاللاڭ" id="start_time" v-model="formData.vip_expires_in" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
                <b-form-invalid-feedback id="vip_expires_in-live-feedback">
                  ئالىي ئەزالىق ئاخىرلىشىش ۋاقتى
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-button id="submit" type="submit" variant="primary">
            ساقلاش
          </b-button>
          <b-button class="ml-2" type="reset" variant="danger">
            بىكار قىلىش
          </b-button>
        </b-form>
      </template>
    </portlet>
    </b-overlay>

  </div>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  import ImageUploader from "../../partials/layout/_ImageUploader";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import ApiService from "../../../common/api.service";
  import {FIND_USER, SET_ADVERTS_ERROR} from "../../../store/types";
  import { FIND_ADVERTS } from "../../../store/types";
  import handleReject from "../../../common/handleReject";
  import sweetAlertMixin from "../../../mixins/common/sweetAlert.mixin";

  export default {
    name: "UserForm",
    props: {
      action: {
        type: String,
        default: "create"
      }
    },
    mixins: [validationMixin, sweetAlertMixin],
    validations: {
      formData: {

        vip_expires_in: { required },
        app_id: { required: requiredIf(formData=>{
          return formData.source === 'miniprogram';
        })}
      }
    },
    components: { Portlet, ImageUploader, datetime: Datetime},
    computed: {
      portletTitle(){
        let create = "يېڭىدىن ئەزا قوشۇش";
        let edit = "ئەزا ئۇچۇرىنى تەھرىرلەش";
        return this.action === "create" ? create : edit;
      },
      oldImage(){
        return this.action === 'edit' && this.formData.cover_image || '';
      },
    },
    created() {
      if ( this.action === 'edit' )
        this.getCurrentAdvert()
    },
    data(){
      return {
        targetTitle: '',
        busy: false,
        formData: {
          vip_expires_in: null,
        },
      };
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning('تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ');
          return;
        }
        if ( this.action === 'create' )
          this.createAdvert();
        else if ( this.action === 'edit' )
          this.updateAdvert();
      },
      createAdvert(){
        this.busy = true;
        ApiService.setHeader();
        ApiService.post(`/manager/advert`, this.formData)
          .then(response=>{
            this.busy = false;
            this.$store.commit(SET_ADVERTS_ERROR, null);
            return this.$router.push({name: 'adverts.list'});
          })
          .catch((response)=>{
            this.busy = false;
            handleReject(this.$store, response, SET_ADVERTS_ERROR);
          })
      },
      updateAdvert(){
        this.busy = true;
        ApiService.setHeader();
        ApiService.put(`/manager/user/${this.formData.id}`, this.formData)
          .then(response=>{
            this.busy = false;
            this.$store.commit(SET_ADVERTS_ERROR, null);
            this.success('تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!');
          })
          .catch(({ response })=>{
            this.busy = false;
            handleReject(this.$store, response, SET_ADVERTS_ERROR);
          })
      },
      onReset(){
        return this.$router.replace({name: 'adverts.list'});
      },
      onUploadSuccess(response){
        this.formData.cover_image =response.path;
      },
      getCurrentAdvert(){
        let id = this.$route.params.id;
        this.busy = true;
        this.$store.dispatch(FIND_USER, id)
          .then(response=>{
            let { data } = response;
            this.formData = data;
            let { chain_related } = data;
            chain_related = chain_related ? chain_related : {};
            this.targetTitle = chain_related.title;
            this.busy = false;
          })
          .catch(response=>{
            this.busy = false;
          })
      },
      onChangeSource(e){
        console.log(e);
        this.formData.chain = null;
        this.targetTitle = null;
      },
      toggleArticleModal(){
        this.articleModalState = ! this.articleModalState;
      },
      showArticleModal(){
        this.toggleArticleModal();
      },
      closeArticleModal(){
        this.articleModalState = false;
      },
      onChooseArticle(e){
        this.targetTitle = e.title;
        this.formData.chain = e.id;
        this.$nextTick(()=>{
          this.toggleArticleModal();
        })
      },

      toggleCategoryModal(){
        this.categoryModalState = ! this.categoryModalState;
      },
      showCategoryModal(){
        this.toggleCategoryModal();
      },
      closeCategoryModal(){
        this.categoryModalState = false;
      },
      onChooseCategory(e){
        this.targetTitle = e.title;
        this.formData.chain = e.id;
        this.$nextTick(()=>{
          this.toggleCategoryModal();
        })
      },

      toggleAuthorModal(){
        this.authorModalState = ! this.authorModalState;
      },
      showAuthorModal(){
        this.toggleAuthorModal();
      },
      closeAuthorModal(){
        this.authorModalState = false;
      },
      onChooseAuthor(e){
        this.targetTitle = e.title;
        this.formData.chain = e.id;
        this.$nextTick(()=>{
          this.toggleAuthorModal();
        })
      },
      ImgColor() {
      let domImg = document.querySelector('img'); // 获取图片dom节点
      let colorthief = new ColorThief();
      domImg.addEventListener('load', () => { // 图片加载
        console.log('主色调', this.rgbaToHex(colorthief.getColor(domImg)));//图片主色调，第二个参数可选(1~10)
      })
    },
    rgbaToHex(rgba) { // rgba转16进制
      let hex = '#';
      for (const i of rgba) {
        hex += i.toString(16).padStart(2, '0');
      }
      return hex;
    },
    }
  }
</script>

<style scoped>
  .input-group i{
    line-height: 1;
  }
</style>
