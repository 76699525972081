<template>
  <div class="create-advert">
    <error :errors="userError" />
    <user-form action="edit" />
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import UserForm from "../../../components/user/UserForm";
  import Error from "../../../partials/widgets/Error";
  import { mapGetters } from "vuex";
  export default {
    name: "index",
    components: { UserForm, Error },
    computed: {
      ...mapGetters(['userError'])
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەزا باشقۇرۇش", route: "/user/list" },
        { title: "ئۇچۇر تەھرىرلەش" }
      ]);
    },
    data(){
      return {};
    },
    methods: {
    }
  }
</script>

<style scoped>

</style>
